import React from 'react'
import { style } from '@/components/Home/Index.style'
import Page from '@/components/Page/PageTop'
import Content from '@/components/Content/Content'
import SectionTitle from '@/components/SectioinTitle'
import MyBtn from '@/components/MyBtn'

const pageTitle = '北関東・甲信越HIV感染症症例検討会'

const Home = () => {
  return (
    <Page title={pageTitle}>
      <Content>
        <section>
          <p>
            「北関東・甲信越HIV感染症症例検討会」は、北関東甲信越地域（栃⽊県・群⾺県・⻑野県・⼭梨県・新潟県）の中核拠点病院の連携と協⼒のもとエイズ治療拠点病院等の医療従事者を対象に、各地域の事情、地⽅特有の問題点につき情報交換の機会をつくり、北関東甲信越地域のHIV医療の資質向上を⽬的として年に1回開催しています。
          </p>
          <p>
            <br />
            関東甲信越ブロック&emsp;エイズ治療ブロック拠点病院
            <br />
            新潟大学医歯学総合病院&emsp;感染管理部
          </p>
        </section>
        <section css={style.flex}>
          <div css={[style.towcolumn, style.border]}>
            <SectionTitle sectionTitle="次回開催" />
            <div css={style.sectionBody}>
              <p css={[style.mainText, style.topText]}>
                <strong>第25回症例検討会 開催案内</strong>
              </p>
              <p css={style.mainText}>
                会期2025年
                <br />
                ・開催日時：
                <br />
                2025年1月31日（金）15時〜16時30分
                <br />
                ・Zoomによるオンライン開催
              </p>
              <p css={style.mainText}>
                <br />
                内容にご関心ある場合や参加登録を希望される場合は、恐れ入りますがご所属とお名前をご記載のうえ下記「問い合わせ」までご連絡ください。
                <br />
                よろしくお願いいたします。
              </p>
            </div>
          </div>
          <div css={style.towcolumn}>
            <SectionTitle
              sectionTitle="第24回症例検討会（2024年1⽉26⽇開催）
          アーカイブ"
            />
            <div css={style.sectionBody}>
              <p css={[style.mainText, style.topText]}>
                当コンテンツはパスワード保護されております。
                <br />
                下記の
                <strong>［保護されたページを表示］</strong>
                ボタンをクリックし、
                <em>ユーザー名</em>と<em>パスワード</em>
                をご入力ください。
              </p>
              <MyBtn href="./main" text="保護されたページを表示" css={style.myBtn} />
              <p css={style.mainText}>
                北関東甲信越エイズ治療拠点病院等の医療従事者を対象とした症例検討会のアーカイブ配信ページです。
                <br />
                ご視聴を希望される場合は、恐れ入りますがご所属とお名前をご記載のうえ下記
                <strong>「問い合わせ」</strong>
                までご連絡ください。
              </p>
              <p>よろしくお願いいたします。</p>
            </div>
          </div>
        </section>
      </Content>
    </Page>
  )
}
export default Home
